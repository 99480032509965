<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title class="font-weight-bold mb-3 mt-10"
        >تفاصيل الأضافة</v-card-title
      >
      <v-row v-if="edit_loader">
        <v-col v-for="(a, i) in 3" :key="i" cols="6" md="3">
          <v-skeleton-loader type="card-heading"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="form" v-if="!edit_loader">
        <v-tabs class="mb-3" v-model="tabs" background-color="gray">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-model="tabs"
            v-for="(lang, i) in $store.getters.langs"
            :key="i"
          >
            {{ lang.lang_name }}
          </v-tab>
        </v-tabs>
        <!--/tabs -->

        <v-tabs-items v-model="tabs">
          <v-tab-item v-for="(lang, i) in $store.getters.langs" :key="i">
            <v-row class="pb-3">
              <v-col cols="12" sm="3">
                <v-text-field
                  :error="name_err"
                  v-model="addition.name[lang.locale]"
                  solo
                  hide-details
                >
                  <template slot="label">
                    <strong class="red--text" v-if="tabs == 0">*</strong>
                    الاسم
                  </template>
                </v-text-field>
                <p v-if="name_err && tabs == 0" class="error--text">
                  الاسم مطلوب
                </p>
              </v-col>
              <!--/ col  company name-->

              <v-col cols="12" sm="3" v-if="tabs == 0">
                <v-text-field
                  v-model="addition.price"
                  solo
                  type="number"
                  hide-details
                >
                  <template slot="label"> السعر </template>
                </v-text-field>
              </v-col>
              <!--/ col  company name-->

              <v-col cols="12" md="3" v-if="tabs == 0">
                <v-select
                  rows="1"
                  solo
                  :items="add_status"
                  item-text="text"
                  item-value="value"
                  v-model="addition.status"
                >
                  <template slot="label">حالة الأضافة</template>
                </v-select>
              </v-col>
              <!--/ col -->
            </v-row>
            <!--/ row -->
          </v-tab-item>
        </v-tabs-items>
        <!--/ col image-->
        <v-btn
          :loading="save_loader"
          :disabled="save_loader"
          color="primary"
          class="save mt-4"
          width="250"
          height="50"
          @click="save()"
          >حفظ</v-btn
        >
      </v-form>
      <!-- / form -->
    </v-card>
    <!--/ card -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حفظ اللاحظة بنجاح</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar color="error" left v-model="snackbar_error">
      <p>برجاء مراجعة البيانات</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">cancel</v-icon>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "New_category",
  data() {
    return {
      tabs: 0,
      edit_loader: false,
      save_loader: false,
      snackbar_success: false,
      snackbar_error: false,
      form: true,
      name_err: false,
      addition: {
        name: {},
        price: 0,
        status:1,
      },
      add_status: [
        {
          text: "متوفر",
          value: 1,
        },
        {
          text: "اخفاء",
          value: 0,
        },
        {
          text: "غير متوفر",
          value: 2,
        },
      ],
    };
  },
  methods: {
    validate() {
      if (this.addition.name[this.$store.getters.langs[0].locale]) {
        this.form = true;
        this.name_err = false;
        return true;
      } else {
        this.tabs = 0;
        this.form = false;
        this.name_err = true;
        return false;
      }
    },
    save() {
      if (this.validate()) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.$route.params.id
                ? `additions/${this.$route.params.id}`
                : `additions`,
              method: this.$route.params.id ? "put" : "post",
            },
            data: this.addition,
          })
          .then((res) => {
            this.snackbar_success = true;
            this.save_loader = false;
            setTimeout(() => {
              this.$router.push("/Additions");
            }, 1500);
          })
          .catch((err) => {
            this.snackbar_error = true;
            this.save_loader = false;
          });
      }
    },
    edit_addition() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `additions/${this.$route.params.id}`,
            method: "get",
          },
        })
        .then((res) => {
          let data = res.data.data;
          data.add_name.forEach((e) => {
            this.addition.name[e.locale] = e.name;
          });
          this.addition.price = data.price;
          this.addition.status = data.status;
          this.edit_loader = false;
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.edit_loader = true;
      this.edit_addition();
    }
  },
};
</script>

<style lang="scss" scoped>
.save {
  font-size: 20px;
}
::v-deep {
  .v-skeleton-loader__heading {
    width: 80% !important;
    height: 40px;
  }
}
</style>
